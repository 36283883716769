<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VToolbarTitle>Конфигурация СМС</VToolbarTitle>

            <VSpacer />

            <VBtn
              depressed
              color="primary"
              :to="{ name : Names.R_TALENT_SMS_CREATE }"
            >
              <VIcon left>
                fal fa-plus
              </VIcon>

              Создать
            </VBtn>
          </VToolbar>

          <VCardText>
            <VDataTable
              :headers="headers"
              :items="preparedCompanies"
              :server-items-length="pagination.total"
              :loading="smsConfigsLoading"
              @pagination="handleSmsConfigPagination"
            >
              <template #item.companyName="{ item }">
                <div>{{ item.name }}</div>
              </template>
              <template #item.enabled="{ item }">
                <VIcon :color="item.enabled ? 'success' : 'warning'">
                  fal {{ item.enabled ? 'fa-check-circle' : 'fa-times-circle' }}
                </VIcon>
              </template>
              <template #item.actions="{ item }">
                <VTooltip left>
                  <template #activator="{ on, attrs }">
                    <VBtn
                      icon
                      small
                      color="orange"
                      class="mx-1"
                      :to="{name : Names.R_TALENT_SMS_ID_EDIT, params : { companyId : item.companyId }}"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <VIcon small>
                        fal fa-edit
                      </VIcon>
                    </VBtn>
                  </template>

                  <span>Редактирование</span>
                </VTooltip>
              </template>
            </VDataTable>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'SmsList',
  inject: ['Names'],
  data() {
    return {
      sms: [],
      companies: [],
      pagination: {
        total: 0,
        limit: 10,
        offset: 0,
      },
      smsConfigsLoading: false,
      headers: [
        {
          text: 'Название компании',
          align: 'left',
          sortable: true,
          value: 'companyName',
        },
        {
          text: 'ID компании',
          align: 'left',
          sortable: true,
          value: 'companyId',
        },
        {
          text: 'Активная',
          align: 'left',
          sortable: true,
          value: 'enabled',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    preparedCompanies() {
      return this.sms.map((item) => {
        const find = this.companies.find((company) => company.id === item.companyId);
        return {
          ...item,
          name: find && find.name,
        };
      });
    },
  },
  created() {
    this.getCompanies();
  },
  methods: {
    async handleSmsConfigPagination({ itemsLength, itemsPerPage, page }) {
      this.smsConfigsLoading = true;

      const limit = itemsPerPage === -1 ? itemsLength : itemsPerPage;
      const offset = page > 0 ? (page - 1) * limit : 0;

      this.$di.api.Talent.smsConfigIndex({ limit, offset })
        .then((response) => {
          if (response && response.smsConfigs && Array.isArray(response.smsConfigs)) {
            this.sms = response.smsConfigs;
          }

          this.pagination = {
            total: response.total || 0,
            offset: response.offset || 0,
            limit: response.limit || 10,
          };
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.smsConfigsLoading = false;
        });
    },
    resetAndReloadSmsConfigPagination() {
      const defaultPagination = {
        itemsLength: 0,
        itemsPerPage: 10,
        page: 1,
      };

      this.handleSmsConfigPagination(defaultPagination);
    },
    getCompanies() {
      this.$di.api.Account.indexCompany()
        .then((response) => {
          this.companies = response.companies;
        })
        .catch((error) => console.warn(error));
    },
  },
};
</script>
