var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VCard',[_c('VToolbar',{attrs:{"flat":""}},[_c('VToolbarTitle',[_vm._v("Конфигурация СМС")]),_c('VSpacer'),_c('VBtn',{attrs:{"depressed":"","color":"primary","to":{ name : _vm.Names.R_TALENT_SMS_CREATE }}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Создать ")],1)],1),_c('VCardText',[_c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.preparedCompanies,"server-items-length":_vm.pagination.total,"loading":_vm.smsConfigsLoading},on:{"pagination":_vm.handleSmsConfigPagination},scopedSlots:_vm._u([{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name))])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('VIcon',{attrs:{"color":item.enabled ? 'success' : 'warning'}},[_vm._v(" fal "+_vm._s(item.enabled ? 'fa-check-circle' : 'fa-times-circle')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"orange","to":{name : _vm.Names.R_TALENT_SMS_ID_EDIT, params : { companyId : item.companyId }}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование")])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }